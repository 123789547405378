<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Viale%20antonio%20la%20falce%2085,%20san%20lorenzo%20del%20vallo,%20biesse%20solution&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Il Migliore Ente di Formazione Accreditato e Certificato</h2>
                    <p>Progettazione, organizzazione e coordinamento di interventi di qualificazione, riqualificazione, specializzazione, perfezionamento, aggiornamento e riconversione professionale rivolte a professionisti, lavoratori, imprenditori, al fine di facilitarne l’inserimento/reinserimento nel mondo del lavoro.
                        Progettazione, organizzazione e coordinamento di corsi di formazione in materia di Salute e Sicurezza sui Luoghi di Lavoro.</p>
                    <p>La società nell’erogazione della propria formazione si avvale di tecniche comunicative innovative, amplificate dal continuo aggiornamento tecnologico e dalle migliori metodologie didattiche riuscendo così a fornire agli allievi un metodo unico di apprendimento, rapido veloce e potente.</p>
                    <p>
                        La capacità di adattare gli argomenti proposti a specifiche necessità settoriali, frutto del contatto istituzionale che ha con numerosi esperti, permette alla società di fornire anche corsi erogati direttamente nelle aziende che potranno così formare un numero consistente di propri addetti, sulla base della specifica situazione aziendale.
                    </p>

                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Cosa abbiamo di Speciale?</h2>
                    <p>
                        Aiutiamo enti, associazioni, collegi e ordini professionali nell’organizzazione di attività formative. Con i nostri consulenti siamo in grado di contattare telefonicamente professionisti e aziende spiegando loro l’importanza delle iniziative. Attraverso una piccola tipografia interna, siamo in grado di stampare imbustare e spedire centinaia di lettere indirizzate ad ogni singolo professionista con il programma del corso organizzato.
                    </p>
                    <p>
                        Con i migliori professionisti che collaborano con la nostra struttura in modo continuativo, abbiamo la possibilità di progettare gratuitamente percorsi formativi Ad Hoc in base alla categoria e all’esigenza del cliente.
                    </p>
                    <p>
                        L’ offerta formativa che proponiamo permette all’organizzazione un cospicuo vantaggio economico, e un vantaggio economico anche agli associati della stessa. L’utile che riusciamo a ricavare dall’organizzazione di un evento, è dettato dalla collaborazione che abbiamo ormai da tempo instaurato con i nostri collaboratori: Professionisti incaricati in qualità di Docenti, Software House, Case Editrici, Ecc.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Viale A. La Falce 85, 87040<br>San Lorenzo del Vallo (CS) - Italia</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+3909811848023">(+39) 0981-1848023</a></p>
                            <p>e-mail: <a href="mailto:iscrizioni@corsinapoli.com">iscrizioni@corsinapoli.com</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                            <p>Sabato: 09:00 - 13:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
